let resumeData = {
    "imagebaseurl":"https://arch10.github.io/",
    "name": "Archit Raj",
    "role": "Android and Backend Developer",
    "linkedinId":"arch10",
    "emailid": "arch1824@gmail.com",
    "skypeid": "arch1824@hotmail.com",
    "roleDescription": " I like dabbling in various parts of android development and like to learn about new technologies, or simply play games in my free time.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/arch6",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"http://github.com/arch10",
          "className":"fa fa-github"
        },
        {
          "name":"skype",
          "url":"http://twitter.com/arch1006",
          "className":"fa fa-twitter"
        }
      ],
    "aboutme":"I am currently working as a Full Stack Developer at Unisys India. I am a self taught Android Developer and have a keen interest in it. I believe that to be successful in life, one needs to be obsessive with their dreams and keep working towards them.",
    "address":"India",
    "website":"https://arch10.github.io",
    "education":[
      {
        "UniversityName":"SRM Institute of Science and Technology, KTR",
        "specialization":"Computer Science",
        "MonthOfPassing":"May",
        "YearOfPassing":"2018",
        "CGPA":"9.2",
        "Achievements":"Event Coordinator in AARUUSH ‘15 (National level techno management fest) of event 221-B Baker Street."
      },
      {
        "UniversityName":"DAV International School, Kharghar",
        "specialization":"Science",
        "MonthOfPassing":"April",
        "CGPA":"92%",
        "YearOfPassing":"2014",
      }
    ],
    "work":[
      {
        "CompanyName":"Unisys",
        "role":"Software Developer",
        "MonthOfLeaving":"Curent",
        "YearOfLeaving": "",
        "MonthOfJoining": "July",
        "YearOfJoining": "2018",
      },
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"Java"
      },
      {
        "skillname":"Android"
      },
      {
        "skillname":"Reactjs"
      }
    ],
    "portfolio":[
      {
        "name":"Calculator Plus",
        "description":"A simple calculator with lots of customization and all the latest features.",
        "imgurl":"images/portfolio/calculatorplus.png",
        "project_url": "https://github.com/arch10/Calculator-Plus/blob/master/README.md"
      },
      {
        "name":"Sparkle",
        "description":"A simple android application for selling perfumens.",
        "imgurl":"images/portfolio/sparkle.jpg",
        "project_url": "https://github.com/arch10/Sparkle/blob/master/README.md"
      },
      {
        "name":"PewDiePie Stickers",
        "description":"A WhatsApp sticker app with pewdiepie reactions.",
        "imgurl":"images/portfolio/pewdiepie.png",
        "project_url": "https://github.com/arch10/stickers/blob/pewdiepie/Android/README.md"
      },
      {
        "name":"BloodBank",
        "description":"BloodBank is an android app which helps the patients who require blood. This app sends blood requirement requests to the registered donors. Donors who are willing to donate can then accept the request and go for the donation.",
        "imgurl":"images/portfolio/bloodbank.jpg",
        "project_url": "https://github.com/arch10/stickers/blob/pewdiepie/Android/README.md"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData